import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import CondicionesDesktop from "./condicionesDesktop";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Titlebar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [folletos, setFolletos] = useState([]);
  const params = useParams();
  const cont = params.cont;

  useEffect(() => {
    // 👇️ only runs once
    window.scrollTo(0, 0)
  }, []);

    return (
    <>
    <CondicionesDesktop folletos={folletos} matches={matches} cont={cont}/>
    </>
  );
}
