import {
  Autocomplete,
  Avatar,
  CircularProgress,
  Divider,
  Fab,
  Paper,
  TextField,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box, Stack, Grid, Button } from "@mui/material";
import { Colors } from "../../styles/theme";
import Appbar from "../appbar";
import Products from "../products";
import { useEffect, useState } from "react";
import Footer from "../footer";
import { UIProvider } from "../../context/ui";
import SearchBox from "../search";
import AppDrawer from "../drawer";
import Offers from "../offers";
import { AppTitleBlue, AppTitleOrange } from "../../styles/app";
import Carousel from "../carousel";
import Horizontalgallery from "../horizontalgallery";
import { useTheme } from "@emotion/react";
import axios from "axios";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import es from "date-fns/locale/es";
import { Fragment } from "react";
import { addMonths } from "date-fns";
import OurFeatures from "./OurFeatures";
import SimpleMap from "./googleMap";

function Home(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchesmdUp = useMediaQuery(theme.breakpoints.up("md"));
  const matcheslaptopDown = useMediaQuery(theme.breakpoints.down("laptop"));
  const [circuitos, setCircuitos] = useState([]);
  const [mosaicos, setMosaicos] = useState([]);
  const [ofertas, setOfertas] = useState([]);
  const [flagWait, setFlagWait] = useState(false);
  const [flagWaitGrupal, setFlagWaitGrupal] = useState(false);
  const [flagWaitOfertas, setFlagWaitOfertas] = useState(false);
  const [ciudades, setCiudades] = useState([]);
  const [paises, setPaises] = useState([]);
  const [paisesSelect, setPaisesSelect] = useState([]);
  const [ciudadesSelect, setCiudadesSelect] = useState([]);
  // const [fecha, setFecha] = useState(addMonths(new Date(), 3));
  const [fecha, setFecha] = useState(new Date());
  const [fechaDesde, setFechaDesde] = useState(new Date());
  const [fechaHasta, setFechaHasta] = useState(new Date());
  const params = useParams();
  const idV = params.opcion;

  // console.log(theme);
  // console.log(matchesmdUp);
  // console.log(matcheslaptopDown)

  useEffect(() => {
    // 👇️ only runs once
    document.title = "Viajes Iberoluna";
    setFechaHasta(
      new Date(
        fechaDesde.getFullYear() + 2,
        fechaDesde.getMonth(),
        fechaDesde.getDate()
      )
    );
    window.scrollTo(0, 0);
    fillCircuitos();
    // fillOfertas();
    fillPaises();
    fillCiudades();
    fillMosaicos();
  }, []);

  const fillMosaicos = async () => {
    setFlagWait(true);
    // const url = "https://iberolunatravel.online/wse/api/getmosaicos?seccion=*";
    const url = "../../data/salidasGrupales.json";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setMosaicos(resp.data);
    setFlagWaitGrupal(false);
  };

  const fillPaises = async () => {
    // const url = "https://iberolunatravel.online/wse/api/getPaises";
    const url = "../../data/paises.json";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setPaises(resp.data);
  };

  const fillCiudades = async () => {
    // const url = "https://iberolunatravel.online/wse/api/getCiudades";
    const url = "../../data/ciudades.json";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setCiudades(resp.data);
  };
 
  const handleBuscadorCircuitos = async () => {
    window.open("#/results/Circuitos/*", "_self");
  };

  const handleBuscadorOfertas = async () => {
    window.open("#/results/Ofertas/*", "_self");
  };

  const fillCircuitos = async () => {
    setFlagWait(true);
    // const url =
    //   "https://iberolunatravel.online/wse/api/SearchCircuitsHome?tipo=Circuitos";
    const url = "../../data/circuitosHome.json";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setCircuitos(resp.data);
    setFlagWait(false);
  };

  const fillOfertas = async () => {
    setFlagWaitOfertas(true);
    const url =
      "https://iberolunatravel.online/wse/api/SearchCircuitsHome?tipo=Ofertas";
    const resp = await axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    setOfertas(resp.data);
    setFlagWaitOfertas(false);
  };

  const applyFilter = async () => {
    var paises = "";
    paisesSelect.forEach(function (pais, key) {
      if (paises === "") {
        paises = pais.PAIS;
      } else {
        paises = paises + "," + pais.PAIS;
      }
    });

    var ciudades = "";
    ciudadesSelect.forEach(function (ciudad, key) {
      if (ciudades === "") {
        ciudades = ciudad.CIUDAD;
      } else {
        ciudades = ciudades + "," + ciudad.CIUDAD;
      }
    });

    var feccir =
      fecha.getFullYear() +
      "-" +
      (fecha.getMonth() + 1).toString().padStart(2, "0");
    paises = paises === "" ? "All" : paises;
    ciudades = ciudades === "" ? "All" : ciudades;
    window.open(
      "#/results/Circuitos/All/" + feccir + "/" + paises + "/" + ciudades + "/*",
      "_self"
    );
  };

  const Item = styled(Paper)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    backgroundColor: "transparent",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "center",
    color: Colors.blue_ibero,
    fontWeight: "bold",
  }));

  const MultiFiltro = () => {
    let content = [];
    content.push(
      <Box
        flex={1}
        sx={{
          minWidth: "183px",
          height: matchesmdUp && matcheslaptopDown ? "280px" : "340px",
          p: "15px",
          borderColor: Colors.blue_light,
          backgroundColor: Colors.blue_light,
          padding: "25px",
          borderRadius: "15px",
          borderStyle: "solid",
          borderWidth: "1px",
          paddingBottom: "10px",
          marginTop: "0px",
          boxShadow: 1,
        }}
      >
        <Stack spacing={2} justifyContent="space-between">
          {matchesmdUp && matcheslaptopDown ? (
            <></>
          ) : (
            <div
              style={{
                backgroundColor: Colors.blue_ibero,
                padding: "10px",
                textAlign: "center",
                borderRadius: "15px",
              }}
            >
              <Typography sx={{ color: Colors.white, fontWeight: "bold" }}>
                {props.opcion} Busque su destino
              </Typography>
            </div>
          )}

          <Item elevation={0}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={es}
            >
              <DatePicker
                openTo="month"
                variant="inline"
                views={["year", "month"]}
                size="small"
                label="Mes y año del viaje"
                minDate={fechaDesde}
                maxDate={fechaHasta}
                value={fecha}
                onChange={(newValue) => {
                  setFecha(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={null}
                    /*      sx={{
                      bgcolor: "white",
                    }} */
                    sx={{
                      bgcolor: "white",
                      width: "100%",
                      "& label": { fontSize: "20px", fontWeight: "bold" },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Item>

          <Item elevation={0}>
            {/*  <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              options={top100Films}
              sx={{
                bgcolor: "white",
              }}
              renderInput={(params) => <TextField {...params} label="Paises" />}
            /> */}
            <Autocomplete
              value={paisesSelect}
              id="country-select-demo"
              // sx={{ width: 100 }}
              sx={{ bgcolor: "white" }}
              //options={countries}
              options={paises}
              autoHighlight
              multiple={true}
              getOptionLabel={(option) => option.PAIS}
              onChange={(event, value) => setPaisesSelect(value)}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.CODIGO_IATA.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.CODIGO_IATA.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.PAIS}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione los países deseados"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Item>

          <Item elevation={0}>
            {/*   <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              options={top100Films}
              sx={{
                bgcolor: "white",
              }}
              renderInput={(params) => (
                <TextField {...params} label="Ciudades" />
              )}
            /> */}

            <Autocomplete
              value={ciudadesSelect}
              id="city-select-demo"
              // sx={{ width: 100 }}
              sx={{ bgcolor: "white" }}
              options={ciudades}
              autoHighlight
              multiple={true}
              getOptionLabel={(option) => option.CIUDAD}
              onChange={(event, value) => setCiudadesSelect(value)}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option.CIUDAD}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione las ciudades deseadas"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Item>

          <Item elevation={0}>
            {matchesmdUp && matcheslaptopDown ? (
              <Button
                variant="contained"
                disableElevation
                sx={{
                  bgcolor: Colors.orange_ibero,
                  color: Colors.white,
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
                onClick={applyFilter}
              >
                Busque su destino
              </Button>
            ) : (
              <Button
                variant="contained"
                disableElevation
                sx={{
                  bgcolor: Colors.orange_ibero,
                  color: Colors.white,
                  marginLeft: "25px",
                  marginRight: "42px",
                  width: "200px",
                }}
                onClick={applyFilter}
              >
                Filtrar
              </Button>
            )}
          </Item>
        </Stack>
      </Box>
    );
    return content;
  };

  /*   const MultiFiltroHorizontal = () => {
    let content = [];
    content.push(
      <Fragment>
        <br></br>
        <Box
          sx={{ backgroundColor: Colors.blue_light, width: "100%" }}
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <Box
            flex={1}
            sx={{
              maxWidth: "1100px",
              borderRadius: "20px",
              p: "15px",
              maxHeight: "300px",
              backgroundImage: `url('https://iberolunatravel.com/images/fondobuscador.jpg')`,
              backgroundSize: "cover",
              paddingTop: "70px",
              margin: "25px",
            }}
          >
            <Stack spacing={2}>

              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      views={["year", "month"]}
                      size="small"
                      label="Mes y año del viaje"
                      minDate={new Date("2022-01-01")}
                      maxDate={new Date("2023-06-01")}
                      value={fecha}
                      onChange={(newValue) => {
                        setFecha(newValue);
                      }}
                      sx={{
                        bgcolor: "white",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={null}
                          sx={{
                            bgcolor: "white",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    sx={{
                      bgcolor: "white",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Paises" />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    sx={{
                      bgcolor: "white",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Ciudades" />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      bgcolor: Colors.orange_ibero,
                      color: Colors.white,

                      width: "150px",
                    }}
                    //onClick={applyFilter}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Box>
      </Fragment>
    );
    return content;
  }; */

  const PorDestinos = () => {
    let content = [];
    content.push(
      <Box
        display="flex"
        justifyContent="center"
        textAlign="center"
        s
        sx={{ p: 2 }}
      >
        <Grid container justifyContent="center" textAlign="center" spacing={4}>
          {props.matches ? <Grid item xs={1} sm={1}></Grid> : <></>}

          <Grid item xs={6} sm={2}>
            <Link
              to={"/results/Destino/EUROPA"}
              style={{ textDecoration: "none" }}
            >
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Avatar
                  src={"/images/imagessmall/Paris_20210421231447.jpg"}
                  sx={medidasAvatarContinentes()}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: Colors.blue_ibero,
                  }}
                >
                  Europa
                </Typography>
              </Stack>
            </Link>
          </Grid>

          <Grid item xs={6} sm={2}>
            <Link
              to={"/results/Destino/MEXICO"}
              style={{ textDecoration: "none" }}
            >
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Avatar
                  src={"/images/imagessmall/oaxaca_20210426181004.jpg"}
                  sx={medidasAvatarContinentes()}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: Colors.blue_ibero,
                  }}
                >
                  México y Cuba
                </Typography>
              </Stack>
            </Link>
          </Grid>

          <Grid item xs={6} sm={2}>
            <Link
              to={"/results/Destino/ORIENTE"}
              style={{ textDecoration: "none" }}
            >
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Avatar
                  src={"/images/imagessmall/El%20Cairo_20210223144220.jpg"}
                  sx={medidasAvatarContinentes()}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: Colors.blue_ibero,
                  }}
                >
                  Turquía y Oriente Medio
                </Typography>
              </Stack>
            </Link>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Link
              to={"/results/Destino/ASIA"}
              style={{ textDecoration: "none" }}
            >
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Avatar
                  src={"/images/imagessmall/pekin_20210420211152.jpg"}
                  sx={medidasAvatarContinentes()}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: Colors.blue_ibero,
                  }}
                >
                  Asia y Oceanía
                </Typography>
              </Stack>
            </Link>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Link
              to={"/results/Destino/USA"}
              style={{ textDecoration: "none" }}
            >
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Avatar
                  src={"/images/imagessmall/las%20vegas_20210421180229.jpg"}
                  sx={medidasAvatarContinentes()}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: Colors.blue_ibero,
                  }}
                >
                  Estados Unidos y Canadá
                </Typography>
              </Stack>
            </Link>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Link
              to={"/results/Destino/EUROPAFLUVIAL/2024-05/All/All/All"}
              style={{ textDecoration: "none" }}
            >
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Avatar
                  src={"/images/imagessmall/budapest_20210317130401.jpg"}
                  sx={medidasAvatarContinentes()}
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: Colors.blue_ibero,
                  }}
                >
                  Cruceros fluviales
                </Typography>
              </Stack>
            </Link>
          </Grid>

          {props.matches ? <Grid item xs={1} sm={1}></Grid> : <></>}
        </Grid>
      </Box>
    );
    return content;
  };

  function Ofertas() {
    let content = [];
    content.push(
      <Fragment>
        <br></br>
        <br></br>
        <Divider></Divider>
        <br></br>
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          sx={{ p: 2 }}
        >
          <AppTitleOrange variant={!matches ? "h3" : "h4"}>
            Circuitos en Ofertas
          </AppTitleOrange>
        </Box>
        {flagWaitOfertas ? (
          <Box
            sx={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{ display: "flex", p: 2 }}
            alignItems="center"
            justifyContent="center"
          >
            <Products
              circuitos={ofertas}
              matches={matches}
              opcionhome={idV}
              ofertas={true}
            />            
          </Box>
        )}

        <br></br>
        <Stack
          direction="column"
          spacing={0}
          width="100%"
          justifyContent="center"
          alignItems="center"
          marginBottom="35px"
        >
          <Fab
            variant="extended"
            aria-label="delete"
            size="large"
            sx={{
              bgcolor: Colors.orange_ibero,
              color: Colors.white,
              textTransform: "none",
              fontSize: "1.5rem",
              width: matches ? "370px" : "600px",
              "&:hover": {
                backgroundColor: Colors.blue_ibero,
              },
            }}
            onClick={(event) => handleBuscadorOfertas()}
          >
            <SearchOutlinedIcon
              fontSize="40px"
              sx={{ color: Colors.white, marginRight: "5px" }}
            />
            Buscador de Ofertas
          </Fab>
        </Stack>
      </Fragment>
    );
    return content;
  }

  const medidasAvatarContinentes = () => {
    return !matches
      ? {
          width: 220,
          height: 220,
          boxShadow: 4,
          "&:hover": {
            transform: "scale(1.02) perspective(0px)",
            boxShadow: 20,
          },
        }
      : {
          width: 155,
          height: 155,
          boxShadow: 4,
          "&:hover": {
            transform: "scale(1.02) perspective(0px)",
            boxShadow: 12,
          },
        };
  };

  const bannerStyle = () => {
    return {
      boxShadow: "1px 2px 9px Gray",
      marginTop: "5px",
    };
  };

  function Carrusel() {
    let content = [];
    content.push(
      <Fragment>
        <Grid item xs={!matches ? 9 : 12}>
          <Carousel indice={false} />
          <SearchBox />
        </Grid>
      </Fragment>
    );
    return content;
  }

  function ParteSuperior() {
    let content = [];
    content.push(
      <Fragment>
        {" "}

        <Grid
          container
          spacing={1}
          sx={{
            padding: "15px !important",
          }}
        >
          {!matches ? <Fragment>{Carrusel()}</Fragment> : <Fragment></Fragment>}
          <Grid item xs={!matches ? 3 : 12}>
            {MultiFiltro()}
          </Grid>
        </Grid>
        <Divider></Divider>
      </Fragment>
    );
    return content;
  }

  function ParterInferior() {
    let content = [];
    content.push(
      <Fragment>
        {" "}
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          sx={{ p: 2 }}
        >
          <AppTitleOrange
            variant={!matches ? "h3" : "h4"}
            color="comon.white"
            textAlign="center"
          >
            Circuitos por el mundo
          </AppTitleOrange>
        </Box>
        {PorDestinos()}
        {/* {Ofertas()} */}
        <br></br>
        <Divider></Divider>
        <br></br>
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          sx={{ p: 2 }}
        >
          <AppTitleBlue
            variant={!matches ? "h3" : "h4"}
            color="comon.white"
            textAlign="center"
          >
            Los mejores circuitos de la temporada
          </AppTitleBlue>
          {/* <Typography variant="h4" color="comon.white">Circuitos Especiales</Typography> */}
        </Box>
        {flagWait ? (
          <Box
            sx={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{ display: "flex", p: 2 }}
            alignItems="center"
            justifyContent="center"
          >
            <Products
              circuitos={circuitos}
              matches={matches}
              opcionhome={idV}
              ofertas={false}
            />
          </Box>
        )}
        <br></br>
        <Stack
          direction="column"
          spacing={0}
          width="100%"
          justifyContent="center"
          alignItems="center"
          marginBottom="35px"
        >
          <Fab
            variant="extended"
            aria-label="delete"
            size="large"
            sx={{
              bgcolor: Colors.blue_ibero,
              color: Colors.white,
              textTransform: "none",
              fontSize: "1.5rem",
              width: matches ? "370px" : "600px",
              "&:hover": {
                backgroundColor: Colors.orange_ibero,
              },
            }}
            onClick={(event) => handleBuscadorCircuitos()}
          >
            <SearchOutlinedIcon
              fontSize="40px"
              sx={{ color: Colors.white, marginRight: "5px" }}
            />
            Buscador de Circuitos
          </Fab>
        </Stack>
        <Divider></Divider>
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          sx={{ p: 4 }}
        >
          <AppTitleOrange
            variant={!matches ? "h3" : "h4"}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={() => navigate("/salidasIberoluna/listing-iberofertas")}
            // onClick={() => navigate("/masvendidos")}
          >
            Los mejores programas a Europa
          </AppTitleOrange>        
        </Box>
        {flagWaitGrupal ? (
          <Box
            sx={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Horizontalgallery
            matches={matches}
            lista={mosaicos.filter((it) => it.seccion === "Grupal")}
          />
        )}
{/* <br></br>
        <Divider></Divider>
        <Box
          display="flex"
          justifyContent="center"
          textAlign="center"
          sx={{ p: 4 }}
        >
        <Stack spacing={2}>
          <AppTitleOrange
            variant={!matches ? "h3" : "h4"}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={() => navigate("/masvendidos")}
          >
            ¿Por qué escoger Viajes Iberoluna?
          </AppTitleOrange>     
          <OurFeatures/>   
          </Stack>
        </Box> */}

{/* hjhj
        <SimpleMap></SimpleMap>
        1515 */}

        <br></br>{" "}
        <Link to={"/europamundo"} style={{ textDecoration: "none" }}>
          {matches ? (
            <img
              loading="lazy"
              width="100%"
              src={"../../images/QueCircuitoMovil.jpg"}
              srcSet={"../../images/QueCircuitoMovil.jpg"}
              alt="Que es Europamundo"
              style={{ boxShadow: "1px 2px 9px Gray", marginTop: "5px" }}
            />
          ) : (
            <img
              loading="lazy"
              width="100%"
              src={"../../images/QueCircuito.jpg"}
              srcSet={"../../images/QueCircuito.jpg"}
              alt="Que es Europamundo"
              style={{ boxShadow: "1px 2px 9px Gray", marginTop: "5px" }}
            />
          )}
        </Link>
      </Fragment>
    );
    return content;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        sx={{
          background: "#fff",
          padding: "0px !important",
        }}
      >
        <Stack>
          <UIProvider>
            <Appbar id="Appbar" />
            {!matches ? (
              <Fragment>
                {ParteSuperior()}
                {ParterInferior()}
              </Fragment>
            ) : (
              <Fragment>
                {ParteSuperior()}
                <div>
                {Carrusel()}
                </div>
                {ParterInferior()}
              </Fragment>
            )}
            <Footer />
            <AppDrawer />
          </UIProvider>
        </Stack>
      </Container>
    </ThemeProvider>
  );
}

export default Home;
